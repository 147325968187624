export interface IS3Credentials {
  aws_pulic_key: string;
  bucket_object_prefix: string;
  expires: number;
  policy: string;
  signature: string;
  storageClass: string | null;
}

export interface IS3Signature {
  credentials?: IS3Credentials;
  fresh: boolean;
  user_id?: number;
}
