export interface IPhotographerOAuthContext {
  clientId: string;
  code: string;
}

export interface IPhotographerOAuthResponse {
  token: string;
  refreshId: string;
  expiration: number;
}
