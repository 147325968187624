export interface ISession {
  token: string;
  expiration: number;
  refreshId: string;
  access?: string;
}

export class Session implements ISession {
  token!: string;
  access?: string;
  expiration!: number;
  refreshId!: string;
}
