import {Inject, Injectable, Optional} from '@angular/core';
import {LOCAL_STORAGE_SALT} from '../../tokens/local-storage-salt.token';
import {ILocalStorageService} from '../../entities/local-storage-service';

@Injectable()
export class LocalStorageService implements ILocalStorageService {
  constructor(@Optional() @Inject(LOCAL_STORAGE_SALT) private salt: string) {
    if (!this.salt) {
      console.warn('provide storage salt');
    }
  }

  private getSaltedKey(key: string): string {
    return this.salt ? `${this.salt}_${key}` : key;
  }

  setItem(key: string, value: string): void {
    localStorage.setItem(this.getSaltedKey(key), value);
  }
  getItem(key: string): string | null {
    return localStorage.getItem(this.getSaltedKey(key));
  }

  removeItem(key: string): void {
    localStorage.removeItem(this.getSaltedKey(key));
  }

  getSerialized<T>(key: string): T | null {
    const item = this.getItem(key);
    return item ? JSON.parse(item) : null;
  }

  setSerialized<T>(key: string, value: T): void {
    this.setItem(key, JSON.stringify(value));
  }

  clear(): void {
    localStorage.clear();
  }
}
