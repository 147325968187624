import {PlatformEnvironment} from '@px/shared/env';

export abstract class Interceptor {
  protected platform!: PlatformEnvironment;

  protected isAPIRequest(url: string): boolean {
    return url.startsWith(this.platform.API_URL);
  }

  protected isImageAPIRequest(url: string): boolean {
    return url.startsWith(this.platform.API_IMG);
  }

  protected isGQLRequest(url: string): boolean {
    return url.startsWith(this.platform.GRAPH_QL_ENDPOINT);
  }

  protected isGQLRefresh(url: string): boolean {
    return url.startsWith(`${this.platform.AUTH_API_URL}/refresh`);
  }

  protected isAuthRequest(url: string): boolean {
    switch (url) {
      case `${this.platform.API_URL}check-login/`:
      case `${this.platform.AUTH_API_URL}/user`:
        return true;

      default:
        return false;
    }
  }
}
