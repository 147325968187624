import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {PlatformEnvironment} from '@px/shared/env';
import {Observable} from 'rxjs';
import {Interceptor} from './interceptor';

@Injectable()
export class ByPassServiceWorkerClientInterceptor extends Interceptor implements HttpInterceptor {
  constructor(protected override readonly platform: PlatformEnvironment) {
    super();
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const {url} = request;

    if (this.isGQLRequest(url)) {
      request = request.clone({
        headers: request.headers.set('ngsw-bypass', 'on'),
      });
    }

    return next.handle(request);
  }
}
