import {Type} from 'class-transformer';
import {Photo} from './photo';
import {ISegment} from '../interfaces/slideshow';
import {Template} from './template';
import {Audio} from './audio';

export class Segment implements ISegment {
  slug!: string;

  @Type(() => Template)
  beat_matching_template!: Template | null;
  is_beatmatching!: boolean;

  @Type(() => Photo)
  photos!: Photo[];
  audio_start!: number;

  @Type(() => Template)
  template!: Template;
  order!: number;
  has_manual_photos_order!: boolean;
  is_published!: boolean;
  current_sort!: string;

  @Type(() => Audio)
  audio!: Audio;
  id!: number;
  audio_end!: number;
  name!: string;
}
