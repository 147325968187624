import {ISlideshow, ISlideShowClient} from './slideshow';
import {IPreferences} from './preferences';
import {IUserClient} from './user';
import {IUrls} from './urls.interface';

export interface ISlideshowResponse {
  slideshow: ISlideshow;
  user_preferences: IPreferences;
}

export interface ISlideshowClientResponse {
  commit_id: string;
  debug_mode: boolean;
  user: IUserClient;
  urls: IUrls;
  FACEBOOK_APP_ID: string;
  password_protected: boolean;
  slide_show: ISlideShowClient;
}
