import {inject, Injectable} from '@angular/core';
import {IPreferences} from '../interfaces/preferences';
import {IPreferencesService} from '../interfaces/preferences-service.interface';
import {ReminderStatus} from '../enums/reminder-status.enum';
import {IApiResponse, Languages} from '@px/shared/env';
import {filter, map, Observable} from 'rxjs';
import {DeepPartial, Primitive} from 'ts-essentials';
import {getPreferencesDiff} from '../functions/get-preferences-diff';
import {Apollo, gql} from 'apollo-angular';
import {IAccount} from '@px/shared-account';
import {PRODUCT_FAMILY, ProductFamily} from '@px/shared/data-access/product-product-family';
import {
  IPreferences as Preferences,
  IPreferencesCustomDomainValidation,
  IPreferencesInput,
  IPreferencesStudioLogo,
  IPreferencesStudioLogoInput,
  PreferencesStudioLogoType,
} from '@px/shared/preferences/data-access';
import {ICustomerSubscription} from '@ps/pricing-domain';

@Injectable({
  providedIn: 'root',
})
export class PreferencesGqlService implements IPreferencesService {
  private readonly apollo = inject(Apollo);
  private readonly productFamily = inject(PRODUCT_FAMILY);

  private readonly getCustomerPreferencesQuery = gql<
    {account: IAccount; customerPreferences: Preferences; subscriptions: ICustomerSubscription[]},
    {productFamily: ProductFamily}
  >`
    query GetCustomerPreferences($productFamily: ProductFamily!) {
      account: myAccount {
        pxId
        email
        profile {
          firstName
          lastName
          studioName
          websiteUrl
          metaData
        }
      }
      customerPreferences: customerPreferences(productFamily: $productFamily) {
        gaCode
        isSharpeningOn
        displayName
        customDomain
        language
        metaData
        studioLogo {
          url
          type
        }
      }
      subscriptions: customerSubscriptions(productFamily: $productFamily) {
        nextBillingDate
        package {
          products {
            price {
              product {
                name
                metaData
              }
            }
          }
        }
      }
    }
  `;

  private readonly isDisplayNameExistentQuery = gql<
    {isDisplayNameExistent: boolean},
    {productFamily: ProductFamily; displayName: string}
  >`
    query IsDisplayNameExistent($displayName: String!, $productFamily: ProductFamily!) {
      isDisplayNameExistent: isDisplayNameExistent(displayName: $displayName, productFamily: $productFamily)
    }
  `;

  private readonly isCustomDomainValidatedQuery = gql<
    {isCustomDomainValidated: IPreferencesCustomDomainValidation},
    {productFamily: ProductFamily; domain: string}
  >`
    query IsCustomDomainValidated($domain: String!, $productFamily: ProductFamily!) {
      isCustomDomainValidated: isCustomDomainValidated(domain: $domain, productFamily: $productFamily) {
        errorCode
        result
      }
    }
  `;

  private readonly updateCustomerPreferencesMutation = gql<void, {customerPreferences: Partial<IPreferencesInput>}>`
    mutation UpdateCustomerPreferences($customerPreferences: UpdateCustomerPreferencesInput!) {
      updateCustomerPreferences(customerPreferences: $customerPreferences) {
        productFamily
      }
    }
  `;

  private readonly updateCustomerPreferencesStudioLogoMutation = gql<
    {updateCustomerPreferences: Pick<Preferences, 'studioLogo'>},
    {customerPreferences: Partial<IPreferencesInput>}
  >`
    mutation UpdateCustomerPreferencesStudioLogo($customerPreferences: UpdateCustomerPreferencesInput!) {
      updateCustomerPreferences(customerPreferences: $customerPreferences) {
        studioLogo {
          type
          url
        }
      }
    }
  `;

  private getDefaultStudioLogo(preferences: Pick<Preferences, 'studioLogo'>): IPreferencesStudioLogo | undefined {
    return (
      preferences.studioLogo.find(logo => logo.type === PreferencesStudioLogoType.LIGHT) ??
      preferences.studioLogo.find(logo => logo.type === PreferencesStudioLogoType.DEFAULT)
    );
  }

  private transformEmptyToNull(value: string | undefined | null): string | null | undefined {
    return value === '' ? null : value;
  }

  getChanges(state: IPreferences, remoteState: IPreferences): DeepPartial<IPreferences> {
    return getPreferencesDiff(state, remoteState);
  }

  load(): Observable<IApiResponse<IPreferences>> {
    return this.apollo
      .query({
        query: this.getCustomerPreferencesQuery,
        variables: {productFamily: this.productFamily},
      })
      .pipe(
        filter(result => !!result.data),
        map(result => {
          const {account, customerPreferences, subscriptions} = result.data;

          return {
            data: {
              website: account.profile.websiteUrl,
              studio_name: account.profile.studioName,
              email: account.email,

              ga_code: customerPreferences.gaCode,
              full_name: `${account.profile.firstName} ${account.profile.lastName}`,
              is_sharpen: customerPreferences.isSharpeningOn,
              display_name: customerPreferences.displayName,
              custom_domain: customerPreferences.customDomain,
              language: customerPreferences.language as Languages,
              business_logo: this.getDefaultStudioLogo(customerPreferences)?.url,

              meta_data: {...customerPreferences.metaData} as Record<string, Primitive | Record<string, Primitive>>,
              // todo: move to metaData
              segment_beat_matched_hint_shown: !!customerPreferences.metaData.segment_beat_matched_hint_shown,
              tpp_shown: !!customerPreferences.metaData.tpp_shown,
              waveform_pin_enabled: !!customerPreferences.metaData.waveform_pin_enabled,
              reminder_status: customerPreferences.metaData.reminder_status as ReminderStatus,
              editor_thumbnail_size: customerPreferences.metaData.editor_thumbnail_size as string,
              last_chosen_video_background: customerPreferences.metaData.last_chosen_video_background as string,
              first_bm_segment_created: !!customerPreferences.metaData.first_bm_segment_created,
              image_upload_hint_shown: !!customerPreferences.metaData.image_upload_hint_shown,
              video_background_notification_shown: !!customerPreferences.metaData.video_background_notification_shown,

              current_period_end: +subscriptions[0].nextBillingDate,
              ga_code_allowed: subscriptions[0].package.products[0].price?.product.metaData.PSS_GA_CODE_ALLOWED === '1',
              plan_name: subscriptions[0].package.products[0].price?.product.name,
            },
            message: '',
            success: true,
          };
        })
      );
  }

  save(preferences: IPreferences): Observable<IApiResponse> {
    return this.apollo
      .mutate({
        mutation: this.updateCustomerPreferencesMutation,
        variables: {
          customerPreferences: {
            productFamily: this.productFamily,
            gaCode: preferences.ga_code as string,
            language: preferences.language as string,
            isSharpeningOn: preferences.is_sharpen as boolean,
            customDomain: this.transformEmptyToNull(preferences.custom_domain),
            displayName: this.transformEmptyToNull(preferences.display_name),
            studioLogo:
              preferences.business_logo || preferences.business_logo === null
                ? [
                    {
                      type: PreferencesStudioLogoType.LIGHT,
                      filePath: preferences.business_logo as string,
                    },
                  ]
                : undefined,
            metaData: {
              ...preferences.meta_data,
              segment_beat_matched_hint_shown: preferences.segment_beat_matched_hint_shown,
              tpp_shown: preferences.tpp_shown,
              waveform_pin_enabled: preferences.waveform_pin_enabled,
              reminder_status: preferences.reminder_status,
              editor_thumbnail_size: preferences.editor_thumbnail_size,
              last_chosen_video_background: preferences.last_chosen_video_background,
              first_bm_segment_created: preferences.first_bm_segment_created,
              image_upload_hint_shown: preferences.image_upload_hint_shown,
              video_background_notification_shown: preferences.video_background_notification_shown,
            } as Record<string, unknown>,
          },
        },
        update: cache => {
          cache.evict({id: 'ROOT_QUERY', fieldName: 'customerPreferences'});
          cache.gc();
        },
      })
      .pipe(map(() => ({data: undefined, message: '', success: true}) as IApiResponse));
  }

  saveStudioLogo(studioLogo: IPreferencesStudioLogoInput): Observable<IPreferencesStudioLogo> {
    return this.apollo
      .mutate({
        mutation: this.updateCustomerPreferencesStudioLogoMutation,
        variables: {
          customerPreferences: {
            productFamily: this.productFamily,
            studioLogo: [studioLogo],
          },
        },
      })
      .pipe(
        filter(result => !!result.data),
        map(result => {
          return this.getDefaultStudioLogo(
            result.data?.updateCustomerPreferences as Preferences
          ) as IPreferencesStudioLogo;
        })
      );
  }

  checkSubdomainAvailable(subdomain: string): Observable<IApiResponse> {
    return this.apollo
      .query({
        query: this.isDisplayNameExistentQuery,
        variables: {displayName: subdomain, productFamily: this.productFamily},
      })
      .pipe(
        filter(result => !!result.data),
        map(result => {
          if (result.data.isDisplayNameExistent) {
            throw {data: undefined, message: '', success: false};
          }
          return {data: undefined, message: '', success: true} as IApiResponse;
        })
      );
  }

  isCustomDomainValidated(domain: string): Observable<IPreferencesCustomDomainValidation> {
    return this.apollo
      .query({
        query: this.isCustomDomainValidatedQuery,
        variables: {domain, productFamily: this.productFamily},
      })
      .pipe(
        filter(result => !!result.data),
        map(result => result.data.isCustomDomainValidated)
      );
  }

  dropCache(): void {
    throw new Error('Method not implemented.');
  }
}
