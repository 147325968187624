import {Observable} from 'rxjs';
import {IPreferences} from './preferences';
import {DeepPartial} from 'ts-essentials';
import {
  IPreferencesCustomDomainValidation,
  IPreferencesStudioLogo,
  IPreferencesStudioLogoInput,
} from '@px/shared/preferences/data-access';
import {IApiResponse} from './api-response';

/**
 * @deprecated
 */
export interface IPreferencesService<T extends IPreferences = IPreferences> {
  getChanges(state: T, remoteState: T): DeepPartial<T>;
  load(): Observable<IApiResponse<T>>;
  save(preferences: DeepPartial<T>): Observable<IApiResponse>;
  saveStudioLogo(studioLogo: IPreferencesStudioLogoInput): Observable<IPreferencesStudioLogo>;
  checkSubdomainAvailable(subdomain: string): Observable<IApiResponse>;
  isCustomDomainValidated(domain: string): Observable<IPreferencesCustomDomainValidation>;
  dropCache(): void;
}
