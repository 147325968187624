export enum AudioCategoryId {
  MY_UPLOADS = 0,
  PARTY_TIME = 1,
  HAPPY = 2,
  CALM_MELODIES = 3,
  BEATS = 4,
  EMOTIONAL = 5,
  LOVE_SONGS = 6,
  EPIC_BEAT_MATCHING = 7,
}
