import {Routes} from '@angular/router';

export const appRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadComponent: () => import('@px/pss/slideshow-web-page/feature-empty-url').then(m => m.EmptyUrlComponent),
  },
  {
    path: '404',
    loadComponent: () => import('@px/pss/slideshow-web-page/feature-page-not-found').then(m => m.PageNotFoundComponent),
  },
  {
    path: 'unsupported-browser',
    loadComponent: () =>
      import('@px/pss/slideshow-web-page/feature-unsupported-browser').then(m => m.UnsupportedBrowserComponent),
  },
  {
    path: ':slug',
    loadChildren: () => import('@px/pss/slideshow-web-page/shell').then(m => m.SlideshowWebPageShellModule),
  },
];
