// Difference is Product Family is used for billing firstly
export enum ProductFamily {
  PSS = 'PSS',
  PSF = 'PSF',
  SA = 'SA',
  CP = 'CP',
  BUNDLE = 'BUNDLE',
}

// Product is only FE side entity
export enum Product {
  PSS = 'PSS',
  PSF = 'PSF',
  SA = 'SA',
  CP = 'CP',
  PSF_CA = 'PSF_CA',
  P1 = 'P1',
}
