import {Observable} from 'rxjs';
import {IPhotographerForgotPasswordContext} from './forgot-password-context';
import {IPhotographerOAuthContext} from './oauth-context';
import {ISession} from '@px/shared/session-provider';
import {IPhotographerRegisterContext} from './register-context';
import {IPhotographerAuthContext} from './auth-context';
import {IPhotographerRefreshContext} from './refresh-context';

export interface IPhotographerAuthBackendStrategy {
  refresh(refreshContext: IPhotographerRefreshContext): Observable<ISession>;

  auth(authContext: IPhotographerAuthContext): Observable<ISession>;

  oAuth(oAuthContext: IPhotographerOAuthContext): Observable<ISession>;

  register(registerContext: IPhotographerRegisterContext): Observable<ISession>;

  forgotPassword(forgotPasswordContext: IPhotographerForgotPasswordContext): Observable<boolean>;
}
