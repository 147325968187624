import {instanceToPlain} from 'class-transformer';
import {ITemplate} from '../interfaces/template.interface';
import {TempoType} from '../enums/tempo-type.enum';
import {ITransition} from '../interfaces/transition';

export class Template implements ITemplate {
  is_custom = false;
  name = '';
  tempo_type = '';
  id = -1;
  matching_images = 0;
  version = 1;
  configs_path = '';
  tempo_id = TempoType.SLOW;
  transitions: ITransition[] = [];

  get isSlow(): boolean {
    return this.tempo_id === TempoType.SLOW;
  }

  get isUptempo(): boolean {
    return this.tempo_id === TempoType.UPTEMPO;
  }

  getPlain(): ITemplate {
    return instanceToPlain(this) as ITemplate;
  }
}
