import {inject, Injectable} from '@angular/core';
import {IPreferencesService} from '../interfaces/preferences-service.interface';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map, Observable, switchMap, tap} from 'rxjs';
import {PlatformEnvironment} from '@px/shared/env';
import {IPreferences} from '../interfaces/preferences';
import {DeepPartial} from 'ts-essentials';
import {HttpCacheManager, withCache} from '@ngneat/cashew';
import {getPreferencesDiff} from '../functions/get-preferences-diff';
import {IPreferencesCustomDomainValidation, IPreferencesStudioLogo} from '@px/shared/preferences/data-access';
import {IApiResponse} from '../interfaces/api-response';

@Injectable()
/**
 * @deprecated use domain
 */
export class PreferencesCacheService implements IPreferencesService {
  private readonly preferencesCacheKey = 'preferences';
  private readonly manager = inject(HttpCacheManager, {optional: true});
  private readonly http = inject(HttpClient);
  private readonly platform = inject(PlatformEnvironment);

  getChanges(state: IPreferences, remoteState: IPreferences): DeepPartial<IPreferences> {
    return getPreferencesDiff(state, remoteState);
  }

  load(): Observable<IApiResponse<IPreferences>> {
    const url = `${this.platform.API_URL}preferences/`;

    return this.http.get<IApiResponse<IPreferences>>(url, {
      context: withCache({
        key: this.preferencesCacheKey,
      }),
    });
  }

  save(preferences: IPreferences): Observable<IApiResponse> {
    const url = `${this.platform.API_URL}preferences/`;
    const formData = 'preferences=' + encodeURIComponent(JSON.stringify(preferences));
    const httpOptions = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8'),
    };

    return this.http.post<IApiResponse>(url, formData, httpOptions).pipe(
      tap(() => {
        this.manager?.delete(this.preferencesCacheKey);
      }),
      switchMap(response => this.load().pipe(map(() => response)))
    );
  }

  checkSubdomainAvailable(subdomain: string): Observable<IApiResponse> {
    return this.http.get<IApiResponse>(`${this.platform.API_URL}check-display-name/${subdomain}/`);
  }

  saveStudioLogo(): Observable<IPreferencesStudioLogo> {
    throw new Error('Method not implemented.');
  }

  isCustomDomainValidated(domain: string): Observable<IPreferencesCustomDomainValidation> {
    throw new Error('Method not implemented.');
  }

  dropCache(): void {
    this.manager?.delete(this.preferencesCacheKey);
  }
}
