import {Inject, Injectable} from '@angular/core';
import {BACKEND_CONTEXT, IBackendContext} from '@px/shared-data-access-backend-context';
import {PX_EVENTS_API} from '../px-events-api.token';
import {Observable} from 'rxjs';
import {PxEventsFilter} from '../../entities/px-events-filter';

@Injectable()
export class PxEventsDataService {
  constructor(@Inject(BACKEND_CONTEXT) private backendContext: IBackendContext) {}

  getEventsCount(event: string, filter: PxEventsFilter): Observable<number> {
    return this.backendContext.sendRequest(PX_EVENTS_API, {
      method: 'getEventsCount',
      params: [event, filter],
    });
  }

  trackEvent(event: string, meta: Record<string, unknown>): Observable<void> {
    return this.backendContext.sendRequest(PX_EVENTS_API, {
      method: 'trackEvent',
      params: [event, meta],
    });
  }

  getSlideshowsViewsCount(slideshowIds: string[]): Observable<Record<string, number>> {
    return this.backendContext.sendRequest(PX_EVENTS_API, {
      method: 'getSlideshowsViewsCount',
      params: [slideshowIds],
    });
  }

  trackSlideshowView(slideshowId: string): Observable<void> {
    return this.backendContext.sendRequest(PX_EVENTS_API, {
      method: 'trackSlideshowView',
      params: [slideshowId],
    });
  }
}
