import {Inject, Injectable, Optional} from '@angular/core';
import {TranslateCompiler} from '@ngx-translate/core';
import MessageFormat, {MessageFunction} from '@messageformat/core';
import {IMessageFormatConfig} from './models/message-format-config';
import {MESSAGE_FORMAT_CONFIG} from './tokens/message-format-config.token';

const DEFAULT_CONFIG: IMessageFormatConfig = {
  biDiSupport: false,
  formatters: undefined,
  locales: undefined,
  strictNumberSign: false,
};

@Injectable()
export class TranslateMessageFormatCompiler extends TranslateCompiler {
  private config: IMessageFormatConfig;

  constructor(
    @Optional()
    @Inject(MESSAGE_FORMAT_CONFIG)
    config?: IMessageFormatConfig
  ) {
    super();

    this.config = {
      ...DEFAULT_CONFIG,
      ...config,
    };
  }

  private compileTranslationsDict(
    mf: MessageFormat,
    translations: Record<string, string | unknown>
  ): Record<string, unknown> {
    return Object.entries(translations).reduce(
      (acc, [curKey, curVal]) => {
        if (typeof curVal === 'string') {
          try {
            acc[curKey] = mf.compile(curVal);
          } catch (e) {
            console.error(e);
          }
        } else {
          acc[curKey] = this.compileTranslationsDict(mf, curVal as Record<string, string | unknown>);
        }
        return acc;
      },
      {} as Record<string, MessageFunction<'string'> | unknown>
    );
  }

  compile(value: string, lang: string): MessageFunction<'string'> {
    const messageFormat = new MessageFormat(lang, {
      customFormatters: this.config.formatters,
      biDiSupport: this.config.biDiSupport,
      strict: this.config.strictNumberSign,
    });
    return messageFormat.compile(value);
  }

  compileTranslations(translations: Record<string, string>, lang: string): Record<string, unknown> {
    const messageFormat = new MessageFormat(lang, {
      customFormatters: this.config.formatters,
      biDiSupport: this.config.biDiSupport,
      strict: this.config.strictNumberSign,
    });

    return this.compileTranslationsDict(messageFormat, translations);
  }
}
