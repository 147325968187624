export enum Languages {
  en = 'en',
  pt = 'pt',
  ru = 'ru',
  uk = 'uk',
  it = 'it',
  de = 'de',
  auto = 'auto',
  es = 'es',
  fr = 'fr',
}
