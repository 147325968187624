import {ProductFamily} from '@px/shared/data-access/product-product-family';

export interface IPreferences<T = Record<string, unknown>> {
  customDomain: string;
  displayName: string;
  gaCode: string;
  isSharpeningOn: boolean;
  language: string;
  productFamily: string;
  studioLogo: IPreferencesStudioLogo[];
  watermark: IPreferencesWatermark;
  metaData: T;
}

export interface IPreferencesStudioLogo {
  type: PreferencesStudioLogoType;
  url: string;
}

export enum PreferencesStudioLogoType {
  DARK = 'DARK',
  DEFAULT = 'DEFAULT',
  LIGHT = 'LIGHT',
}

export interface IPreferencesWatermark {
  position: string;
  scale: number;
  url: string;
}

export interface IPreferencesInput {
  customDomain: string | null;
  displayName: string | null;
  gaCode: string;
  isSharpeningOn: boolean;
  language: string;
  metaData: Record<string, unknown>;
  productFamily: ProductFamily;
  studioLogo: IPreferencesStudioLogoInput[];
  watermark: IPreferencesWatermarkInput;
}

export interface IPreferencesStudioLogoInput {
  filePath: string;
  type: PreferencesStudioLogoType;
}

export interface IPreferencesWatermarkInput {
  filePath: string;
  position: string;
  scale: number;
}

export interface IPreferencesCustomDomainValidation {
  errorCode: PreferencesCustomDomainValidationErrorCode;
  result: boolean;
}

export enum PreferencesCustomDomainValidationErrorCode {
  ALREADY_IN_USE = 'ALREADY_IN_USE',
  INVALID = 'INVALID',
  MISCONFIGURED = 'MISCONFIGURED',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}
