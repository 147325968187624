import {inject, Inject, Injectable} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  distinctUntilChanged,
  EMPTY,
  filter,
  fromEvent,
  map,
  merge,
  Observable,
  pairwise,
  shareReplay,
  startWith,
  Subject,
  Subscription,
  switchMap,
  tap,
  timer,
} from 'rxjs';
import {fromFetch} from 'rxjs/fetch';
import {PlatformEnvironment} from '@px/shared/env';
import {DOCUMENT} from '@angular/common';
import {CONNECTION_ISSUE_FEATURE_NAME} from '../consts/feature-name.const';
import {WINDOW_TOKEN} from '@px/cdk/window';

@UntilDestroy()
@Injectable()
export class ConnectionStatusFacadeService {
  private static readonly PING_TIMEOUT_MS = 10000;

  private readonly window = inject(WINDOW_TOKEN);
  private imageDownloadStatus$ = new BehaviorSubject(true);
  private isTrackingInitialized = false;
  private isVisible = true;

  private online$: Observable<boolean> = merge(fromEvent(window, 'online'), fromEvent(window, 'offline')).pipe(
    map(() => this.isBrowserOnline),
    startWith(this.isBrowserOnline),
    shareReplay(1)
  );

  private visible$: Observable<boolean> = fromEvent(this.document, 'visibilitychange').pipe(
    map(() => this.document.visibilityState === 'visible'),
    shareReplay(1)
  );

  private timer$?: Subscription;

  private checkImmediate$ = new Subject<null>();

  hasConnection$ = combineLatest([this.online$, this.imageDownloadStatus$]).pipe(
    map(args => args.every(Boolean)),
    distinctUntilChanged(),
    shareReplay(1)
  );

  private get URL(): string {
    return `${this.platform.S3_URL}cleardot.gif?zx=${new Date().getTime()}`;
  }

  get isBrowserOnline(): boolean {
    return this.window.navigator.onLine;
  }

  constructor(
    private readonly platform: PlatformEnvironment,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  private setImageDownloadStatus(status: boolean): void {
    this.imageDownloadStatus$.next(status);

    if (this.imageDownloadStatus$.getValue()) {
      this.isTrackingInitialized = false;
    }
  }

  private ping(): void {
    this.timer$?.unsubscribe();
    this.timer$ = merge(
      timer(ConnectionStatusFacadeService.PING_TIMEOUT_MS, ConnectionStatusFacadeService.PING_TIMEOUT_MS).pipe(
        filter(() => this.isVisible)
      ),
      this.checkImmediate$
    )
      .pipe(
        switchMap(() => {
          return fromFetch(this.URL, {headers: {'ngsw-bypass': 'on'}}).pipe(
            tap(() => {
              this.setImageDownloadStatus(true);
            }),
            catchError(() => {
              this.setImageDownloadStatus(false);
              return EMPTY;
            })
          );
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  initialize(): void {
    if (!this.platform.hasFeature(CONNECTION_ISSUE_FEATURE_NAME)) {
      return;
    }

    this.ping();

    this.online$.pipe(pairwise(), untilDestroyed(this)).subscribe(([prev, curr]) => {
      if (prev === false && curr === true) {
        this.checkNow();
      }
    });

    this.visible$.pipe(untilDestroyed(this)).subscribe((status: boolean) => {
      this.isVisible = status;
    });
  }

  checkNow(): void {
    this.checkImmediate$.next(null);
  }
}
