import {IConfigCatClient, PollingMode} from 'configcat-common';
import {APP_INITIALIZER, inject, Injectable, StaticProvider} from '@angular/core';
import {PlatformEnvironment} from '../platform-environment';
import {FEATURE_FLAGS_CONFIGURATOR} from '../tokens/feature-flags-configurator.token';
import {BehaviorSubject, firstValueFrom} from 'rxjs';

export const CONFIG_CAT_ASYNC_INITIALIZER: StaticProvider = {
  provide: APP_INITIALIZER,
  useFactory: (): (() => Promise<void>) => {
    const provider = inject(ConfigCatAsyncProvider);
    const platform = inject(PlatformEnvironment);
    const configCat = inject(FEATURE_FLAGS_CONFIGURATOR);

    return async () => {
      if (platform.CONFIG_CAT) {
        await provider.resolve(platform.CONFIG_CAT, platform.DEBUG);
        await firstValueFrom(configCat.configureFeaturesAndConfigs());
      }
    };
  },
  multi: true,
};

@Injectable()
export class ConfigCatAsyncProvider {
  client: IConfigCatClient | null = null;

  clientReady$ = new BehaviorSubject<boolean>(false);

  async resolve(sdkKey: string, debug = false): Promise<void> {
    const configcat = await import('configcat-js');
    const {LogLevel} = await import('configcat-common');

    const logger = configcat.createConsoleLogger(debug ? LogLevel.Warn : LogLevel.Error);

    this.client = configcat.getClient(sdkKey, PollingMode.ManualPoll, {
      logger,
    });

    this.client.on('clientReady', () => {
      this.clientReady$.next(true);
    });
  }
}
