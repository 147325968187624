import {ModuleWithProviders, NgModule} from '@angular/core';
import {SharedDataAccessBackendModule} from '@px/shared-data-access-backend-context';
import {SharedDataAccessLocalStorageModule} from '@px/shared-data-access-local-storage';
import {PhotographerAuthDataService} from './infrastructrue/services/photographer-auth-data.service';
import {PhotographerAuthFacade} from './application/photographer-auth-facade.service';

@NgModule({
  imports: [SharedDataAccessBackendModule, SharedDataAccessLocalStorageModule],
})
export class PhotographerAuthDomainModule {
  static forRoot(): ModuleWithProviders<PhotographerAuthDomainModule> {
    return {
      ngModule: PhotographerAuthDomainModule,
      providers: [PhotographerAuthFacade, PhotographerAuthDataService],
    };
  }
}
