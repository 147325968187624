import {inject, Injectable} from '@angular/core';
import {Product} from '@px/shared/data-access/product-product-family';
import {PlatformEnvironment} from '@px/shared/env';

@Injectable()
export class PhotographerNavigationFacadeService {
  private readonly platform = inject(PlatformEnvironment);

  authCodeUrl(product: Product, state = '/'): string {
    const clientId = this.platform.PX_PRODUCTS_CLIENT_ID?.[product];

    return `${this.platform.AUTH_API_URL}/client?client_id=${clientId}&state=${encodeURIComponent(state)}`;
  }
}
