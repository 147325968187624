export interface IPhotographerRegisterContext {
  clientId: string;
  email: string;
  firstName: string;
  lastName: string;
  studioName: string;
  password: string;
  passwordConfirm: string;
  agreeToTerms: boolean;
}
