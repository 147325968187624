import {NgModule} from '@angular/core';
import {ServiceWorkerModule, SwRegistrationOptions} from '@angular/service-worker';
import {environment} from '../../../environments/environment';
import {CheckUpdatesService} from '@px/update-notification/domain';

@NgModule({
  imports: [ServiceWorkerModule.register('ngsw-worker.js')],
  providers: [
    CheckUpdatesService,
    {
      provide: SwRegistrationOptions,
      useFactory: (): SwRegistrationOptions => ({
        enabled: environment.production,
        registrationStrategy: 'registerWhenStable:30000',
      }),
    },
  ],
})
export class AppServiceWorkerModule {}
