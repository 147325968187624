import {IFeatureImage} from '../interfaces/photo';

export class FeatureImage implements IFeatureImage {
  image_path!: string;
  id!: number;
  active_storage_service!: string;
  image_1080p!: string | null;
  image_1080pWBlur?: string | null;
  width?: number;
  height?: number;

  focal_point_image?: string;
}
