import {inject, Injectable} from '@angular/core';
import {filter, map, Observable, of} from 'rxjs';
import {Apollo, gql} from 'apollo-angular';
import {PRODUCT_FAMILY, ProductFamily} from '@px/shared/data-access/product-product-family';
import {IUserDataGql} from '../models/user-gql-data';

@Injectable({
  providedIn: 'root',
})
export class AuthGqlDataService {
  private readonly apollo = inject(Apollo);
  private readonly productFamily = inject(PRODUCT_FAMILY);
  private readonly userQuery = gql<IUserDataGql, {productFamily: ProductFamily}>`
    query CheckLoginGqlAnalog($productFamily: ProductFamily!) {
      customerPreferences(productFamily: $productFamily) {
        displayName
        customDomain
        language
        gaCode
        metaData
        isSharpeningOn
        studioLogo {
          url
        }
      }
      myAccount {
        pxId
        email
        beaconSignature(productFamily: $productFamily)
        dateCreated
        profile {
          websiteUrl
          studioName
        }
      }
      customerSubscriptions(productFamily: $productFamily, status: ACTIVE) {
        package {
          products {
            price {
              product {
                id
                name
                metaData
              }
            }
          }
        }
      }
    }
  `;

  getUser(): Observable<IUserDataGql> {
    return this.apollo
      .query({
        query: this.userQuery,
        variables: {
          productFamily: this.productFamily,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(
        filter(r => !!r.data),
        map(r => r.data)
      );
  }

  logOut(): Observable<void> {
    return of(undefined);
  }
}
