import {Languages} from '@px/shared/env';
import {ReminderStatus} from '../enums/reminder-status.enum';
import {IPreferences} from '../interfaces/preferences';

export class Preferences implements IPreferences {
  custom_domain?: string;
  display_name?: string;
  business_logo?: string;
  current_period_end?: number | Date | undefined;
  email?: string;
  full_name?: string;
  ga_code?: string | null;
  ga_code_allowed?: boolean;
  is_sharpen?: boolean;
  language?: Languages;
  plan_name?: string;
  reminder_status?: ReminderStatus;
  studio_name?: string;
  image_upload_hint_shown?: boolean;
  tpp_shown?: boolean;
  waveform_pin_enabled?: boolean;
  website?: string;
  last_chosen_video_background?: string;
  video_background_notification_shown?: boolean;
  editor_thumbnail_size?: string;
  cameron_goal_done?: boolean;
  segment_beat_matched_hint_shown?: boolean;
  first_bm_segment_created?: boolean;
}
