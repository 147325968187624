import {AudioCategoryId} from '../enums/audio-categoriy.enum';
import {AudioEnergyId} from '../enums/audio-energy.enum';
import {ITemplate} from './template.interface';

export interface IAudio<T = number> {
  templates: ITemplate<T>[];
  up_tempo: number | null;
  down_tempo: number | null;
  lyrics: string | null;
  energy: string | null;
  is_new: boolean;
  expires_at: number | null;
  genre: string | null;
  original_file_name: string;
  is_favorite?: boolean;
  instrumental: boolean;
  song_title: string;
  audio_mp3: string;
  audio_ogg: string;
  expired: boolean;
  id: T;
  category: string;
  waveform_data: number[];
  artist: string;
  length: number;
  provider: string | null;
  round_added: string | null;
  category_id: AudioCategoryId;
  energy_id: AudioEnergyId | null;
  is_recently_used: number | null;
  is_enabled: boolean;
}

export interface IJoinedAudio {
  mp3: string;
  ogg: string;
}
