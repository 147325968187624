import {InjectionToken} from '@angular/core';
import {IFeatureFlagsConfigurator} from '../interfaces/feature-flags-configurator';
import {ConfigCatFeatureFlagsConfiguratorService} from '../services/config-cat-feature-flags-configurator.service';

export const FEATURE_FLAGS_CONFIGURATOR = new InjectionToken<IFeatureFlagsConfigurator>('feature flags configurator');

export const FEATURE_FLAGS_CONFIGURATOR_PROVIDER = {
  provide: FEATURE_FLAGS_CONFIGURATOR,
  useClass: ConfigCatFeatureFlagsConfiguratorService,
};
