import {Inject, Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import {Router} from '@angular/router';
import {PlatformEnvironment} from '@px/shared/env';
import {ConnectionStatusFacadeService} from '@px/connection-issue/domain';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Interceptor} from './interceptor';
import {LOGOUT_PATH} from '../tokens/logout-path.token';

/**
 * @deprecated use NoAuthInterceptor
 */
@Injectable()
export class ErrorInterceptor extends Interceptor implements HttpInterceptor {
  constructor(
    @Inject(LOGOUT_PATH) private readonly logoutPath: string,
    protected readonly router: Router,
    protected override readonly platform: PlatformEnvironment,
    private readonly connectionStatusFacadeService: ConnectionStatusFacadeService
  ) {
    super();
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((response: unknown) => {
        if (response instanceof HttpErrorResponse) {
          switch (response.status) {
            case HttpStatusCode.Unauthorized:
              if (!this.isAuthRequest(request.url)) {
                this.router.navigate([`/${this.logoutPath}`]);
              }
              break;

            case -1:
            case 0: {
              this.connectionStatusFacadeService.checkNow();
            }
          }
        }

        return throwError(() => response);
      })
    );
  }
}
