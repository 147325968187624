import {Inject, Injectable} from '@angular/core';
import {BACKEND_CONTEXT, IBackendContext} from '@px/shared-data-access-backend-context';
import {Observable} from 'rxjs';
import {PHOTOGRAPHER_AUTH_API} from '../tokens';
import {IPhotographerAuthContext, IPhotographerOAuthContext, IPhotographerRefreshContext} from '../../entities';
import {ISession} from '@px/shared/session-provider';

@Injectable()
export class PhotographerAuthDataService {
  constructor(@Inject(BACKEND_CONTEXT) private backendContext: IBackendContext) {}

  oAuth(oAuthContext: IPhotographerOAuthContext): Observable<ISession> {
    return this.backendContext.sendRequest(PHOTOGRAPHER_AUTH_API, {
      method: 'oAuth',
      params: [
        {
          clientId: oAuthContext.clientId,
          code: oAuthContext.code,
        },
      ],
    });
  }

  auth(context: IPhotographerAuthContext): Observable<ISession> {
    return this.backendContext.sendRequest(PHOTOGRAPHER_AUTH_API, {
      method: 'auth',
      params: [context],
    });
  }

  refresh(refreshContext: IPhotographerRefreshContext): Observable<ISession> {
    return this.backendContext.sendRequest(PHOTOGRAPHER_AUTH_API, {
      method: 'refresh',
      params: [
        {
          clientId: refreshContext.clientId,
          refreshId: refreshContext.refreshId,
        },
      ],
    });
  }
}
