import {Languages} from '../enums/languages';

export interface IPlatformConfig<T extends string = never, D extends Record<string, unknown> = never> {
  APP_NAME: string;
  BASE_LANG: Languages;
  LANGUAGES: Languages[];
  LANGUAGE_LIST: ILanguage[];
  EVENT_DATE_FORMAT_OVERRIDES: EventDateFormatOverrides;

  FEATURES: IFeatures<T>;
  FEATURE_CONFIG: FeatureConfigs<T, D>;
  FEATURES_BODY_CLASSES: T[];

  DISABLE_ONBEFOREUNLOAD: boolean;
  GLOBAL_VOLUME: number;
  DEBUG: boolean;
  SCOPE: string;

  S3_URL: string;
  CF_URL: string;
  UPLOADER_API: string;
  CHARGEBEE_SDK_URL: string;
  CHARGEBEE_SITE: string;

  CHARGEBEE_API_KEY: string;
  IMAGES_PATH: string;
  HOST: string;
  API_URL: string;
  AUTH_API_URL: string;
  GRAPH_QL_ENDPOINT: string;
  GRAPH_QL_REQUESTS_WITH_AUTH_HEADER: string[];
  API_IMG: string;
  PIXELLU_URL: string;
  PIXELLU_ACCOUNT_URL: string;
  PIXELLU_OAUTH_URL: string;
  PSF_HOST: string;
  PX_OAUTH_CLIENT_ID: string;
  NO_AUTH_REDIRECT_URL: string;
  GA_INFO: string;
  AUDIOS_INFO: string;
  BM_INFO: string;
  BM_SEGMENT_INFO: string;
  CLIENT_INTERACTIONS: string;
  CUSTOM_DOMAIN: string;
  EMBED_INFO: string;
  IMAGES_FAILING_INFO: string;
  IMAGES_INFO: string;
  SLIDESHOWS_DURATION_INFO: string;
  SUPPORT_URLS: {[key: string]: string};
  LOGOS_INFO: string;
  GIT_COMMIT: string;
  GIT_BRANCH: string;
  DEPLOY_ENV: string;

  LATEST_BROWSERS_VER: ILatestBrowsersVer;

  SENTRY_ENABLED: boolean;
  SENTRY_DSN: string;

  GA_ENABLED: boolean;
  GA_TRACKING_ID: string;

  MIXPANEL_ENABLED: boolean;
  MIXPANEL_TOKEN: string;

  RUDDERSTACK_ENABLED: boolean;
  RUDDERSTACK_WRITE_KEY: string;
  RUDDERSTACK_DATA_PLANE_URL: string;

  BEACON_ENABLED: boolean;
  BEACON_ID: string;

  GTM_ENABLED: boolean;
  GTM_ID: string;
  GTM_ENV: string;

  DELIGHTED_SNIPPET_KEY: string;
  DELIGHTED_ENABLED: boolean;

  AVAILABLE_FILES_EXTENSIONS: IAvailableFilesExtensions;

  COMMON_SETTINGS: {
    MAX_UPLOAD_FILE_SIZE: number;
  };
  APOLLO_STUDIO_VERSION: string;
}

export interface IAvailableFilesExtensions {
  audio?: string[];
  image?: string[];
}

export interface ILanguage {
  id: number;
  title: string;
  value: Languages;
  disabled?: boolean;
  locales?: string[];
}

export type EventDateFormatOverrides = {
  [k in Languages]?: Intl.DateTimeFormatOptions;
};

export type IFeatures<T extends string> = {
  [k in T]?: boolean;
};

export type FeatureConfigs<T extends string, D extends Record<string, unknown>> = {
  [k in T]?: D[k];
} & Record<string, unknown>;

export interface ILatestBrowsersVer {
  chrome: string;
  firefox: string;
  safari: string;
  edge: string;
}
