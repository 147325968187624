import {inject, Injectable} from '@angular/core';
import {HttpBatchLink} from 'apollo-angular/http';
import type {ApolloClientOptions, NormalizedCacheObject} from '@apollo/client';
import {split} from '@apollo/client';
import type {BatchOptions} from 'apollo-angular/http/types';
import {ApolloOptionsResolverService} from './apollo-options-resolver.service';
import {GraphQLWsLink} from '@apollo/client/link/subscriptions';
import {CloseCode, createClient} from 'graphql-ws';
import {SessionProviderFacade} from '@px/shared/session-provider';
import {filter, firstValueFrom, Subscription, timer} from 'rxjs';

@Injectable()
export class ApolloOptionResolverBatchService extends ApolloOptionsResolverService {
  private readonly httpBatchLink = inject(HttpBatchLink);
  private readonly sessionProviderFacade = inject(SessionProviderFacade);
  private tokenExpirySubscription: Subscription | null = null;

  private httpLinkOptions: BatchOptions = {
    ...this.httpLinkOptionsBase,
    batchMax: 30,
    batchInterval: 20,
  };

  override resolve(): ApolloClientOptions<NormalizedCacheObject> {
    const batchLinkHandler = this.httpBatchLink.create({
      ...this.httpLinkOptions,
      uri: this.platform.GRAPH_QL_ENDPOINT,
    });

    const httpLinkHandler = this.httpLink.create({
      ...this.httpLinkOptions,
      uri: this.platform.GRAPH_QL_ENDPOINT,
    });

    const graphQLWsLink = new GraphQLWsLink(
      createClient({
        lazy: true,
        url: this.platform.GRAPH_QL_ENDPOINT_WS,

        connectionParams: async () => {
          const session = this.sessionProviderFacade.getSessionService();

          if (session?.isSessionExpired()) {
            await firstValueFrom(session?.session$.pipe(filter(() => !session?.isSessionExpired())));
          }

          return {Authorization: `Bearer ${session?.getSessionToken()}`};
        },
        on: {
          connected: socket => {
            const session = this.sessionProviderFacade.getSessionService();

            this.tokenExpirySubscription?.unsubscribe();

            const currentTokenExpiresIn = session?.getCurrentTokenExpiresIn() ?? 0;
            this.tokenExpirySubscription = timer(currentTokenExpiresIn).subscribe(() => {
              if ((socket as WebSocket).readyState === WebSocket.OPEN) {
                (socket as WebSocket).close(CloseCode.Forbidden, 'Forbidden');
              }
            });
          },
          error: error => {
            console.warn(error);
          },
        },
      })
    );

    const baseLink = split(this.batchOperationTest, batchLinkHandler, httpLinkHandler);
    const link = split(this.subscriptionOperationTest, graphQLWsLink, baseLink);

    return {
      ...this.clientOptionsBase,
      cache: this.cache,
      link,
    };
  }
}
