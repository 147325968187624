import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {PlatformEnvironment} from '@px/shared/env';
import {Observable} from 'rxjs';
import {Interceptor} from './interceptor';

@Injectable()
export class ScopeInterceptor extends Interceptor implements HttpInterceptor {
  private static readonly xPxScope = 'x-px-scope';

  constructor(protected override readonly platform: PlatformEnvironment) {
    super();
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const {url} = request;

    if (this.platform.SCOPE && (this.isAPIRequest(url) || this.isImageAPIRequest(url))) {
      const scopeHeader = request.headers.get(ScopeInterceptor.xPxScope);

      if (!scopeHeader) {
        request = request.clone({
          headers: request.headers.append(ScopeInterceptor.xPxScope, this.platform.SCOPE),
        });
      }
    }

    return next.handle(request);
  }
}
