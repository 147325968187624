import {inject, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Languages} from '@px/shared/env';
import {Observable} from 'rxjs';
import {PSSThemesPlatformEnvironment} from '@px/pss-themes/platform-env';

@Injectable()
export class TranslateManagerService {
  private readonly translate = inject(TranslateService);
  private readonly platform = inject(PSSThemesPlatformEnvironment);

  initialize(): void {
    const availableLanguages = this.platform.LANGUAGE_LIST.filter(
      lang => !lang.disabled && lang.value !== Languages.auto
    ).map(lang => lang.value);

    const langs = Array.from(new Set([...availableLanguages, this.platform.BASE_LANG]));

    this.translate.addLangs(langs);
    this.translate.setDefaultLang(this.platform.BASE_LANG);
  }

  useBrowserLang(): Observable<void> {
    const languages = this.translate.getLangs();
    const browserLang = this.translate.getBrowserLang();
    const lang = browserLang && languages.includes(browserLang) ? browserLang : this.platform.BASE_LANG;

    return this.translate.use(lang);
  }

  changeLang(newLang: Languages): Observable<void> {
    const languages = this.translate.getLangs();

    return languages.includes(newLang) ? this.translate.use(newLang) : this.useBrowserLang();
  }
}
