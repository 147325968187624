import {StudioLogo} from './studio-logo';
import {Type} from 'class-transformer';
import {IUserClient} from '../interfaces/user';

export class UserClient implements IUserClient {
  custom_domain!: string;
  website!: string;
  is_sharpen!: boolean;
  hash!: string;
  ga_user_code!: string;
  studio_name!: string;
  business_logo!: string;

  @Type(() => StudioLogo)
  studio_logo!: StudioLogo;
  full_name!: string;
  no_tracking!: boolean;
  id!: number;
  is_trial!: boolean;
  email!: string;
}
