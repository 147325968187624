import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {ConnectionStatusFacadeService} from '@px/connection-issue/domain';
import {PSSThemesPlatformEnvironment} from '@px/pss-themes/platform-env';
import {TranslateManagerService} from '@px/pss-themes/feature-translate';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {CheckUpdatesService} from '@px/update-notification/domain';
import {environment} from '../environments/environment';

@UntilDestroy()
@Component({
  selector: 'pss-root',
  standalone: true,
  imports: [RouterOutlet],
  template: '<router-outlet />',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  private readonly platform = inject(PSSThemesPlatformEnvironment);
  private readonly connectionStatusFacadeService = inject(ConnectionStatusFacadeService);
  private readonly translateManagerService = inject(TranslateManagerService);
  private readonly checkUpdatesService = inject(CheckUpdatesService);

  ngOnInit(): void {
    this.connectionStatusFacadeService.initialize();
    this.translateManagerService.initialize();
    this.translateManagerService.useBrowserLang().pipe(untilDestroyed(this)).subscribe();
    this.platform.printVersion();

    if (environment.production) {
      this.checkUpdatesService.initializeCheck().subscribe();
      this.checkUpdatesService.initializeReloadWhenHidden().subscribe();
    }
  }
}
