import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {translateModuleConfig} from './configs/app-translate.config';
import {TranslateManagerService} from './services/translate-manager.service';

@NgModule({
  imports: [TranslateModule.forRoot(translateModuleConfig)],
  providers: [TranslateManagerService],
})
export class AppTranslateModule {}
