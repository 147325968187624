import {Observable} from 'rxjs';
import {ISession} from './session.interface';
import {SessionSource} from './session-source.enum';
import {IJwtPayload} from './jwt-payload.interface';
import jwtDecode from 'jwt-decode';
import dayjs from 'dayjs';

// Do not pollute this interface with any dependencies
export interface ISessionProvider {
  readonly session: ISession | null;

  readonly session$: Observable<ISession | null>;

  isSessionExpired(): boolean;

  getSessionToken(): string | undefined;

  refresh(): Observable<void>;

  logOut(): void;

  hasSession(): boolean;

  getRoles(): string[];

  getEmail(): string;

  getId(): string;

  updateSession(session: ISession, sessionSource?: SessionSource): void;

  handleNoSession(next?: string): Observable<boolean>;

  getCurrentTokenExpiresIn(): number;
}

export abstract class SessionProvider implements ISessionProvider {
  abstract readonly session: ISession | null;

  abstract readonly session$: Observable<ISession | null>;

  protected readonly EXPIRATION_GAP_SECONDS: number = -30;

  protected getExpiration(): number | null {
    const jwt = this.getSessionToken();
    let expiration = null;

    if (jwt) {
      try {
        const decodedJWT: IJwtPayload = jwtDecode(jwt);
        expiration = decodedJWT.exp;
      } catch (e) {
        console.error(e);
        expiration = null;
      }
    }

    return expiration;
  }

  getCurrentTokenExpiresIn(): number {
    const expiration = this.getExpiration();

    return expiration ? dayjs.unix(expiration).add(this.EXPIRATION_GAP_SECONDS, 's').diff(dayjs(), 'ms') : 0;
  }

  isSessionExpired(): boolean {
    const expiration = this.getExpiration();

    return expiration ? dayjs.unix(expiration).add(this.EXPIRATION_GAP_SECONDS, 's').isBefore(dayjs()) : true;
  }

  abstract getSessionToken(): string | undefined;

  abstract refresh(): Observable<void>;

  abstract logOut(): void;

  abstract hasSession(): boolean;

  abstract getRoles(): string[];

  abstract getEmail(): string;

  abstract getId(): string;

  abstract updateSession(session: ISession, sessionSource?: SessionSource): void;

  abstract handleNoSession(next?: string): Observable<boolean>;
}
