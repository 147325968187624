export interface IOption {
  optional_other: boolean;
  order: number;
  option: string;
  id: number;
}

export interface IQuestion {
  options: IOption[];
  question: string;
  order: number;
  id: number;
}

export interface ISurvey {
  order: number;
  name: string;
  questions: IQuestion[];
  id: number;
}
