import {Type} from 'class-transformer';
import {CallToAction} from './call-to-action';
import {EmbedConfig} from './embed-config';
import {FeatureImage} from './feature-image';
import {FocalPoint} from './focal-point';
import {ICommonData} from '../interfaces/slideshow';
import {Covers} from '../enums/covers.enum';
import {ImageQuality} from '../enums/image-quality.enum';

export class CommonData implements ICommonData {
  video_background!: string;
  is_email_me!: boolean;
  is_audio_changed!: boolean;

  @Type(() => EmbedConfig)
  embed_config!: EmbedConfig;

  is_download_button!: boolean;
  download_protected!: boolean;

  @Type(() => FeatureImage)
  featured_image!: FeatureImage;
  password!: string | null;
  download_options!: ImageQuality[];
  is_view_button!: boolean;

  @Type(() => CallToAction)
  call_to_action!: CallToAction;
  display_filenames!: boolean;
  theme!: Covers;

  @Type(() => FocalPoint)
  focal_point!: FocalPoint;
  state!: number;
  download_pin!: string;
  is_download_pin!: boolean;
  event_date!: number | null;
  is_favorites_allowed!: boolean;
  is_email_registration_allowed!: boolean;

  created!: number;
  updated!: number;
}
