import {inject, Injectable} from '@angular/core';
import * as Bowser from 'bowser';
import {Parser} from 'bowser';
import InApp from 'detect-inapp';
import {PlatformEnvironment} from '@px/shared/env';
import {WINDOW_TOKEN} from '@px/cdk/window';

@Injectable()
export class BrowserService {
  private readonly window: Window = inject(WINDOW_TOKEN);
  private readonly platform = inject(PlatformEnvironment);

  private readonly mobile = 'mobile';

  private readonly parser: Parser.Parser = Bowser.getParser(this.window.navigator.userAgent);

  isMac(): boolean {
    return this.parser.getOSName(true) === 'macos';
  }

  isNotMac(): boolean {
    return this.parser.getOSName(true) === 'macos';
  }

  isMobile(): boolean {
    return this.parser.getPlatformType() === this.mobile;
  }

  isBrowserSupport(): boolean {
    const detectInAppBrowser = new InApp(this.window.navigator.userAgent || this.window.navigator.vendor);
    const isMobileInAppBrowser = detectInAppBrowser.isMobile && detectInAppBrowser.isInApp; // we skip browser check for mobile in-app browsers
    return !!this.parser.satisfies(this.platform.LATEST_BROWSERS_VER) || isMobileInAppBrowser;
  }

  isFirefox(): boolean {
    return this.parser.is('firefox');
  }

  satisfies(checkTree: Bowser.Parser.checkTree): boolean | undefined {
    return this.parser.satisfies(checkTree);
  }
}
