import {IPreferences} from '../interfaces/preferences';
import {DeepPartial} from 'ts-essentials';
import {diff} from 'deep-object-diff';

export function getPreferencesDiff(state: IPreferences, remoteState: IPreferences): DeepPartial<IPreferences> {
  const deepObjectDiff = diff(remoteState, state);

  /* meta_data is nested object, so we need to save actual state of this entire field.
   * Otherwise we will rewrite it with deep object diff and loose all other meta_data nested fields except changed ones
   */
  if ('meta_data' in deepObjectDiff) {
    deepObjectDiff.meta_data = state.meta_data;
  }

  return deepObjectDiff;
}
