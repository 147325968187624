export enum TransitionTypeId {
  CROSS_FADE = 0,
  AUTO = 7,
  HARD_CUT = 1,
  WIPE_UP = 2,
  WIPE_DOWN = 3,
  WIPE_LEFT = 4,
  WIPE_RIGHT = 5,
  DIP_TO_BACKGROUND = 6,
}
