import {Injectable} from '@angular/core';
import {PxEventsDataService} from '../../infrastructure/px-events-data/px-events-data.service';
import {Observable} from 'rxjs';
import {PxEvents} from '../../enums/px-events';

@Injectable()
export class PxEventSlideshowViewFacadeService {
  constructor(private readonly eventsDataService: PxEventsDataService) {}

  getSlideshowViews(uniqueIdentifier: string): Observable<number> {
    return this.eventsDataService.getEventsCount(PxEvents.SLIDESHOW_VIEW.toString(), {
      'slideshow.unique_identifier': {operator: '=', value: uniqueIdentifier},
    });
  }

  trackSlideshowView(slideshowId: string): Observable<void> {
    return this.eventsDataService.trackSlideshowView(slideshowId);
  }

  getSlideshowViewsCount(slideshowIds: string[]): Observable<Record<string, number>> {
    return this.eventsDataService.getSlideshowsViewsCount(slideshowIds);
  }
}
