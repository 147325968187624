import {CommonData} from './common-data';
import {Segment} from './segment';
import {Type} from 'class-transformer';
import {ISlideShowClient, ISlideshowMetaData} from '../interfaces/slideshow';
import {AspectRatio} from '@px/shared-utils-aspect-ratio';

export class SlideshowClient implements ISlideShowClient {
  name!: string;
  unique_identifier!: string;
  original_user_id!: number;

  @Type(() => Segment)
  segments!: Segment[];

  @Type(() => CommonData)
  common_data!: CommonData;
  encoded_name!: string;
  video_1080p!: string;
  video_4k!: string;
  aspect_ratio!: AspectRatio;
  meta_data!: ISlideshowMetaData;
}
