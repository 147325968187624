import {FeatureImage} from './feature-image';
import {IPhoto} from '../interfaces/photo';

export class Photo extends FeatureImage implements IPhoto {
  name!: string;
  override height!: number;
  height_4k!: number | null;
  image_2k_hd!: string;
  image_4k!: string | null;
  is_low_resolution!: boolean;
  order?: number;
  manual_order?: number | null;
  override width!: number;
  width_4k!: number | null;
  thumbnail?: string;
  selected?: boolean;
  image_2k_hd_white?: string | null;
  image_4k_video_white?: string | null;
  image_4k_video?: string | null;
  original_width?: number;
  original_height?: number;
  date_taken!: number;
}
