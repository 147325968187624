import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {PlatformEnvironment} from '@px/shared/env';
import {Observable} from 'rxjs';
import {Interceptor} from './interceptor';

@Injectable()
export class HttpXSRFInterceptor extends Interceptor implements HttpInterceptor {
  constructor(protected override readonly platform: PlatformEnvironment) {
    super();
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const {url} = request;

    if (
      !url.includes('.json') &&
      (this.isAPIRequest(url) || this.isImageAPIRequest(url)) &&
      !this.isGQLRequest(url) &&
      !this.isGQLRefresh(url)
    ) {
      request = request.clone({
        withCredentials: true,
      });
    }

    return next.handle(request);
  }
}
