export interface IUserDataGql {
  customerPreferences: {
    displayName: string;
    customDomain: string | null;
    language: string;
    metaData: {
      tpp_shown?: boolean;
      lastChosenTransitionDuration?: number;
      lastChosenTransitionType?: number;
    };
    isSharpeningOn: boolean;
    gaCode: string | null;
    studioLogo: {url: string}[];
  };
  myAccount: {
    beaconSignature: string | null;
    dateCreated: string;
    email: string;
    pxId: number;
    profile: {
      websiteUrl: string | null;
      studioName: string;
    };
  };
  customerSubscriptions: {
    package: {
      products: {
        price: {
          product: {
            id: string;
            name: string;
            metaData: {
              PSS_IS_TRIAL?: string;
              PSS_VIDEO_DOWNLOAD_CLIENT_ENABLED?: string;
              PSS_VIDEO_4K_ALLOWED?: string;
              PSS_SLIDESHOW_COUNT?: string;
              PSF_GALLERIES_COUNT?: string;
              PSS_VIDEO_ALLOWED?: string;
            };
          };
        };
      }[];
    };
  }[];
}
