import {inject, Injectable} from '@angular/core';
import {IFeatureFlagsConfigurator} from '../interfaces/feature-flags-configurator';
import {PlatformEnvironment} from '../platform-environment';
import {IConfigCatClient, User as UserConfigCat} from 'configcat-common';
import {from, map, Observable, of, switchMap, tap} from 'rxjs';
import isBoolean from 'lodash/isBoolean';
import isNil from 'lodash/isNil';
import {ConfigCatAsyncProvider} from '../providers/config-cat.provider';

@Injectable()
export class ConfigCatFeatureFlagsConfiguratorService<T extends string = string>
  implements IFeatureFlagsConfigurator<T, UserConfigCat>
{
  static readonly FEATURE_CONFIG_PATH_SEPARATOR = '__';

  private readonly platform = inject<PlatformEnvironment<T>>(PlatformEnvironment, {optional: true});
  private readonly configCatAsyncProvider = inject(ConfigCatAsyncProvider);

  private get configCat(): IConfigCatClient | null {
    return this.configCatAsyncProvider.client;
  }

  static propertyIsFeatureConfig(propKey: string): boolean {
    return propKey.includes(this.FEATURE_CONFIG_PATH_SEPARATOR);
  }

  configureFeaturesAndConfigs(userObject?: UserConfigCat): Observable<void> {
    const configCat = this.configCat;

    if (!configCat) {
      return of(undefined);
    }

    return from(configCat.forceRefreshAsync()).pipe(
      switchMap(() => configCat.getAllValuesAsync(userObject)),
      tap(settingKeyValues => {
        for (const {settingKey, settingValue} of settingKeyValues) {
          if (ConfigCatFeatureFlagsConfiguratorService.propertyIsFeatureConfig(settingKey) && !isNil(settingValue)) {
            const [feature, ...path] = settingKey.split(
              ConfigCatFeatureFlagsConfiguratorService.FEATURE_CONFIG_PATH_SEPARATOR
            );

            this.platform?.setFeatureConfig(feature as T, path, settingValue);
          } else if (isBoolean(settingValue)) {
            this.platform?.setFeatureState(settingKey as T, settingValue);
          }
        }
      }),
      map(() => undefined)
    );
  }

  configureFeature(feature: T, userObject: UserConfigCat): Observable<void> {
    const configCat = this.configCat;

    if (!configCat) {
      return of(undefined);
    }

    return from(configCat.forceRefreshAsync()).pipe(
      switchMap(() => configCat.getValueAsync(feature, null, userObject)),
      tap(featureFlag => {
        if (isBoolean(featureFlag)) {
          this.platform?.setFeatureState(feature, featureFlag);
        }
      }),
      map(() => undefined)
    );
  }

  configureFeatureConfig(feature: T, fieldPath: string[], userObject: UserConfigCat): Observable<void> {
    const configCat = this.configCat;

    if (!configCat) {
      return of(undefined);
    }

    const configPathKey = [feature, ...fieldPath].join(
      ConfigCatFeatureFlagsConfiguratorService.FEATURE_CONFIG_PATH_SEPARATOR
    );

    return from(configCat.forceRefreshAsync()).pipe(
      switchMap(() => configCat.getValueAsync(configPathKey, null, userObject)),
      tap(value => {
        if (!isNil(value)) {
          this.platform?.setFeatureConfig(feature, fieldPath, value);
        }
      }),
      map(() => undefined)
    );
  }
}
