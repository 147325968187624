export interface IPICImageLinkData {
  userId: number | null;
  active_storage_service: string;
  image_path: string;
}

export class ImageLinkParser {
  static parseImageLinkToPicImageLinkData(link: string): IPICImageLinkData {
    if (!link) {
      return {
        userId: null,
        active_storage_service: 'aws',
        image_path: '',
      };
    }
    const regex =
      /https:\/\/[^/]+\/(?<active_storage_service>[^/]+)\/[^/]+\/[^/]+\/(?<userId>[^/]+)\/(?<image_path>[^?]+)/;

    const match = link.match(regex);

    if (!match || !match.groups) {
      throw new Error('Invalid link format');
    }

    const {userId, active_storage_service, image_path} = match.groups;

    return {
      userId: Number.parseInt(userId, 10),
      active_storage_service,
      image_path,
    };
  }
}
