import {ErrorHandler, Injectable, StaticProvider} from '@angular/core';
import type * as Sentry from '@sentry/angular-ivy';
import type {User} from '@sentry/types/types/user';
import {PlatformEnvironment} from '@px/shared/env';

@Injectable()
export class SentryErrorHandlerService implements ErrorHandler {
  private readonly deffer$?: Promise<void>;
  private sentry?: typeof Sentry;

  constructor(protected readonly platform: PlatformEnvironment) {
    if (this.platform.SENTRY_ENABLED) {
      this.deffer$ = this.initSentry();
    }
  }

  private async initSentry(): Promise<void> {
    this.sentry = await import('@sentry/angular-ivy');
    const {BrowserTracing} = await import('@sentry/angular-ivy');

    this.sentry.init({
      dsn: this.platform.SENTRY_DSN,
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      environment: this.platform.DEPLOY_ENV,
      release: PlatformEnvironment.APP_VERSION ?? this.platform.GIT_COMMIT,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: this.sentry.routingInstrumentation,
        }),
      ],
      ignoreErrors: [
        // Non-Error exception captured with keys: error, headers, message, name, ok
        'Non-Error exception captured',
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        "Can't find variable: ZiteReader",
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        // Facebook borked
        'fb_xd_fragment',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
        // reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',
      ],
      denyUrls: [
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      ],
    });
  }

  async setUser(user: Partial<User>): Promise<void> {
    if (this.platform.SENTRY_ENABLED) {
      await this.deffer$;
      this.sentry?.setUser(user);
    }
  }

  async handleError(error: Error & {originalError: Error}): Promise<void> {
    if (this.platform.SENTRY_ENABLED) {
      await this.deffer$;
      const id = this.sentry?.captureException(error.originalError || error);
      id && console.error('Sentry event id', id);
    }

    console.error(error.originalError || error);
  }
}

export const SENTRY_ERROR_HANDLER_SERVICE: StaticProvider = {
  provide: ErrorHandler,
  useClass: SentryErrorHandlerService,
};
