import {ISlideshowClientResponse} from '../interfaces/slideshow-response';
import {UserClient} from './user-client';
import {Type} from 'class-transformer';
import {Urls} from './urls';
import {SlideshowClient} from './slideshow-client';
import {Covers} from '../enums/covers.enum';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export class SlideshowClientResponse implements ISlideshowClientResponse {
  commit_id!: string;
  debug_mode!: boolean;

  @Type(() => UserClient)
  user!: UserClient;

  @Type(() => Urls)
  urls!: Urls;
  FACEBOOK_APP_ID!: string;
  password_protected!: boolean;

  @Type(() => SlideshowClient)
  slide_show!: SlideshowClient;

  private static parseDate(eventDate?: number | null): number | null {
    if (!eventDate) {
      return null;
    }

    const date = dayjs.unix(eventDate);
    return date.utc().add(-date.utcOffset(), 'minutes').toDate().getTime();
  }

  isProtected(): boolean {
    return this.password_protected;
  }

  isNotProtected(): boolean {
    return !this.password_protected;
  }

  getTheme(): Covers {
    return this.slide_show.common_data.theme;
  }

  getEventDate(): number | null {
    return SlideshowClientResponse.parseDate(this.slide_show.common_data.event_date);
  }

  getName(): string {
    return this.slide_show.name;
  }

  getLogoUrl(): string {
    return this.user.business_logo;
  }

  getStudioName(): string {
    return this.user.studio_name || this.user.full_name;
  }

  getAnalyticsCode(): string {
    return this.user.ga_user_code;
  }

  isNoTracking(): boolean {
    return this.user.no_tracking;
  }
}
