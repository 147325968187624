import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {PlatformEnvironment} from '@px/shared/env';
import {map, Observable} from 'rxjs';
import {ISlideshowClientResponse} from '../interfaces/slideshow-response';
import {IApiResponse} from '../interfaces/api-response';
import {ISlideshowClientService} from '../interfaces/slideshow-client-service.interface';
import {plainToClass} from 'class-transformer';
import {SlideshowClientResponse} from '../models/slideshow-client-response';

@Injectable()
/**
 * @deprecated use domain
 */
export class SlideshowClientService implements ISlideshowClientService {
  constructor(
    private readonly http: HttpClient,
    private readonly env: PlatformEnvironment
  ) {}

  getInfo(name: string): Observable<SlideshowClientResponse> {
    const hash = encodeURIComponent(btoa(name));

    return this.http
      .get<IApiResponse<ISlideshowClientResponse>>(`${this.env.API_URL}published-images-collection/${hash}/`)
      .pipe(map(response => plainToClass(SlideshowClientResponse, response.data)));
  }

  download(name: string, payload: string): Observable<string> {
    const hash = encodeURIComponent(btoa(name));

    return this.http
      .post<IApiResponse<ISlideshowClientResponse>>(`${this.env.API_URL}published-images-collection/${hash}/`, {
        download_pin: payload,
      })
      .pipe(map(response => response.data.slide_show.video_1080p));
  }

  auth(name: string, password: string): Observable<SlideshowClientResponse> {
    const hash = encodeURIComponent(btoa(name));

    return this.http
      .post<IApiResponse<ISlideshowClientResponse>>(`${this.env.API_URL}published-images-collection/${hash}/`, {
        password,
      })
      .pipe(map(response => plainToClass(SlideshowClientResponse, response.data)));
  }
}
