import {environment} from './environments/environment';
import {PSSThemesPlatformEnvironment} from '@px/pss-themes/platform-env';
import {IPlatformConfig, PlatformEnvironment} from '@px/shared/env';
import {plainToClass, plainToInstance} from 'class-transformer';
import {bootstrapApplication} from '@angular/platform-browser';
import {AppComponent} from './app/app.component';
import {appConfig} from './app/app.config';
import {enableProdMode} from '@angular/core';

if (environment.production) {
  enableProdMode();
}

(async (): Promise<void> => {
  let platform: PSSThemesPlatformEnvironment;

  try {
    const env: IPlatformConfig = await fetch(
      `/assets/configs/environment.json?${PSSThemesPlatformEnvironment.getQueryString()}`
    ).then(res => res.json());

    const overrides: Record<string, IPlatformConfig> = await fetch(
      `/assets/configs/overrides.json?${PSSThemesPlatformEnvironment.getQueryString()}`
    ).then(res => res.json());

    platform = plainToClass(
      PSSThemesPlatformEnvironment,
      Object.assign(Object.assign(env, overrides ?? {}), {
        production: environment.production,
      })
    );
  } catch (e) {
    console.error(e);
    platform = plainToInstance(PSSThemesPlatformEnvironment, {});
  }

  platform.setupHeaders(document.head);

  appConfig.providers.push(
    {provide: PlatformEnvironment, useValue: platform},
    {provide: PSSThemesPlatformEnvironment, useValue: platform}
  );

  bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));
})();
