import {NgModule} from '@angular/core';
import {SERVICE_WORKER_SKIP_INTERCEPTOR} from '@px/shared/env';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {ByPassServiceWorkerInterceptor, HttpXSRFInterceptor, ScopeInterceptor} from '@px/shared/api';

@NgModule({
  providers: [
    SERVICE_WORKER_SKIP_INTERCEPTOR,
    {provide: HTTP_INTERCEPTORS, useClass: HttpXSRFInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ByPassServiceWorkerInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ScopeInterceptor, multi: true},
  ],
})
export class InterceptorsModule {}
