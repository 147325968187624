import {Injectable} from '@angular/core';
import {IPreferencesService} from '../interfaces/preferences-service.interface';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {PlatformEnvironment} from '@px/shared/env';
import {IPreferences} from '../interfaces/preferences';
import {DeepPartial} from 'ts-essentials';
import {getPreferencesDiff} from '../functions/get-preferences-diff';
import {IPreferencesCustomDomainValidation, IPreferencesStudioLogo} from '@px/shared/preferences/data-access';
import {IApiResponse} from '../interfaces/api-response';

@Injectable()
/**
 * @deprecated use domain
 */
export class PreferencesService implements IPreferencesService {
  constructor(
    private http: HttpClient,
    protected readonly platform: PlatformEnvironment
  ) {}

  getChanges(state: IPreferences, remoteState: IPreferences): DeepPartial<IPreferences> {
    return getPreferencesDiff(state, remoteState);
  }

  load(): Observable<IApiResponse<IPreferences>> {
    const url = `${this.platform.API_URL}preferences/`;

    return this.http.get<IApiResponse<IPreferences>>(url);
  }

  save(preferences: IPreferences): Observable<IApiResponse> {
    const url = `${this.platform.API_URL}preferences/`;
    const formData = 'preferences=' + encodeURIComponent(JSON.stringify(preferences));
    const httpOptions = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8'),
    };

    return this.http.post<IApiResponse>(url, formData, httpOptions);
  }

  checkSubdomainAvailable(subdomain: string): Observable<IApiResponse> {
    return this.http.get<IApiResponse>(`${this.platform.API_URL}check-display-name/${subdomain}/`);
  }

  saveStudioLogo(): Observable<IPreferencesStudioLogo> {
    throw new Error('Method not implemented.');
  }

  isCustomDomainValidated(domain: string): Observable<IPreferencesCustomDomainValidation> {
    throw new Error('Method not implemented.');
  }

  dropCache(): void {
    throw new Error('Method not implemented.');
  }
}
