import {CommonData} from './common-data';
import {Segment} from './segment';
import {Type} from 'class-transformer';

export class Slideshow {
  slug!: string;
  name!: string;
  unique_identifier!: string;

  @Type(() => Segment)
  segments!: Segment[];

  @Type(() => CommonData)
  common_data!: CommonData;
  video_4k!: string | null;
  video_1080p!: string | null;
  id!: number;
  build_video_estimated_time!: number;
  original_user_id!: number | null;
  original_collection_url?: string;
}
