import {inject, Injectable} from '@angular/core';
import type {NormalizedCacheObject} from '@apollo/client';
import {ApolloCache, InMemoryCache} from '@apollo/client';
import type {CachePersistor} from 'apollo3-cache-persist';
import {LocalStorageService} from '@px/shared-data-access-local-storage';
import {PlatformEnvironment} from '@px/shared/env';

@Injectable()
export class ApolloCachePersistService {
  private readonly platform = inject(PlatformEnvironment);
  private readonly localStorage = inject(LocalStorageService);
  private readonly cache = new InMemoryCache();

  private cachePersistor?: CachePersistor<NormalizedCacheObject>;

  async setupPersistStore(): Promise<void> {
    const {CachePersistor, LocalStorageWrapper} = await import('apollo3-cache-persist');

    this.cachePersistor = new CachePersistor({
      storage: new LocalStorageWrapper(this.localStorage),
      cache: this.cache,
      debug: this.platform.DEBUG,
      trigger: 'write',
      key: this.platform.APP_VERSION,
    });

    await this.cachePersistor?.restore();
  }

  getCache(): ApolloCache<NormalizedCacheObject> {
    return this.cache;
  }
}
