import {ClassProvider, inject, Injectable} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpRequest} from '@angular/common/http';
import {GqlTokenRefreshInterceptorService} from './gql-token-refresh-interceptor.service';
import {map, Observable, switchMap, throwError} from 'rxjs';
import {AUTH_SERVICE, IAuthService} from '@px/shared/api';

/**
 * JWT Auth is not required here because we don't have any PIC requests with cookies.
 */
@Injectable()
export class PicTokenRefreshInterceptorService extends GqlTokenRefreshInterceptorService {
  private authService: IAuthService = inject(AUTH_SERVICE);

  private isRegularPICRequest(req: HttpRequest<unknown>): boolean {
    const isPic = req.url.startsWith(this.platform.API_URL);

    if (!isPic) {
      return false;
    }

    switch (req.url) {
      case `${this.platform.API_URL}jwt-authorization/`:
      case `${this.platform.API_URL}check-login/`:
      case `${this.platform.API_URL}logout/`:
        return false;
      default:
        return true;
    }
  }

  private isRegularCPRequest(req: HttpRequest<unknown>): boolean {
    return req.url.startsWith(this.platform.CLOUD_PROOFING_API_URL);
  }

  protected override toIntercept(req: HttpRequest<unknown>): boolean {
    return super.toIntercept(req) || this.isRegularCPRequest(req) || this.isRegularPICRequest(req);
  }

  protected override getRefreshingChain(): Observable<void> {
    return super.getRefreshingChain().pipe(
      switchMap(() => {
        const service = this.sessionProviderFacade.getSessionService();

        if (!service?.session) {
          return throwError(() => new Error("Session or SessionProvider doesn't exist!"));
        }

        return this.authService
          .jwtAuthorize({
            jwt: service.session?.token as string,
            refresh_id: service.session?.refreshId as string,
            expiration: service.session?.expiration as number,
          })
          .pipe(map(() => undefined));
      })
    );
  }
}

export const PIC_TOKEN_INTERCEPTOR: ClassProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: PicTokenRefreshInterceptorService,
  multi: true,
};
