import {inject, Injectable} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {PlatformEnvironment} from '../platform-environment';

@Injectable()
export class ServiceWorkerSkipInterceptor implements HttpInterceptor {
  private readonly platform = inject(PlatformEnvironment);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.platform.DISABLE_SERVICE_WORKER) {
      return next.handle(
        request.clone({
          headers: request.headers.set('ngsw-bypass', 'on'),
        })
      );
    }

    return next.handle(request);
  }
}

export const SERVICE_WORKER_SKIP_INTERCEPTOR = {
  provide: HTTP_INTERCEPTORS,
  useClass: ServiceWorkerSkipInterceptor,
  multi: true,
};
