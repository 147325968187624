import {FeatureConfigBase, IPlatformConfig} from '@px/shared/env';

export enum PSSThemesPlatformFeatures {
  CHECK_UPDATES = 'CHECK_UPDATES',
  PSS_THEMES_GRAPHQL_API = 'PSS_THEMES_GRAPHQL_API',
  JPEG_ONLY_IN_PLAYER = 'JPEG_ONLY_IN_PLAYER',
}

export type PSSThemesFeatureList = keyof typeof PSSThemesPlatformFeatures;
export type PSSThemesFeatureConfig = Record<PSSThemesFeatureList, FeatureConfigBase>;

export interface IPSSThemesPlatformConfig extends IPlatformConfig<PSSThemesPlatformFeatures, PSSThemesFeatureConfig> {
  COMMON_SETTINGS: IPSSThemesCommonSettings;
}

export interface IPSSThemesCommonSettings {
  MAX_UPLOAD_FILE_SIZE: number;
  SLIDESHOW_LIMITS: {
    MIN_SLIDE_DURATION: number;
  };
  MAX_DPR: number;
  FRAMES_PER_SEC: number;
  CHANGE_SEGMENT_ANIMATION_TIME: number;
  AUTO_TRANSITION_DURATION: number;
  FADE_TRANSITION_DURATION: {
    START: number;
    END: number;
  };
  LOGO: {
    MIN_HEIGHT: number;
    MIN_THUMB_HEIGHT: number;
    MIN_WIDTH: number;
    AVAILABLE_LOGO_EXTENSIONS: string[];
  };
}
