import {Injectable} from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import {filter, map, Observable} from 'rxjs';
import {IPxEventsApiInterface, PxEventsFilter} from '@px/px-events/domain';

@Injectable()
export class PxEventsGraphqlApiService implements IPxEventsApiInterface {
  private readonly eventsCount = gql<{eventsCount: number}, {eventName: string; filter: PxEventsFilter}>`
    query EventsCount($eventName: String!, $filter: JSON!) {
      eventsCount(eventName: $eventName, filter: $filter)
    }
  `;

  private readonly trackEventMutation = gql<void, {event: {eventName: string; meta: Record<string, unknown>}}>`
    mutation TrackEvent($event: PXEventInput!) {
      trackEvent(event: $event)
    }
  `;

  private readonly slideshowsViewsCount = gql<
    {
      slideshowsViewsCount: Record<string, number>;
    },
    {slideshowIds: string[]}
  >`
    query SlideshowsViewsCount($slideshowIds: [String]!) {
      slideshowsViewsCount(slideshowIds: $slideshowIds)
    }
  `;

  private readonly trackSlideshowViewMutation = gql<void, {slideshowId: string}>`
    mutation trackSlideshowView($slideshowId: String!) {
      trackSlideshowView(slideshowId: $slideshowId)
    }
  `;

  constructor(private apollo: Apollo) {}

  getEventsCount(eventName: string, eventFilter: PxEventsFilter): Observable<number> {
    return this.apollo
      .query({
        query: this.eventsCount,
        variables: {
          eventName,
          filter: eventFilter,
        },
      })
      .pipe(
        filter(result => !!result.data),
        map(result => result.data?.eventsCount)
      );
  }

  trackEvent(eventName: string, meta: Record<string, unknown>): Observable<void> {
    return this.apollo
      .mutate({
        mutation: this.trackEventMutation,
        variables: {
          event: {
            eventName,
            meta,
          },
        },
      })
      .pipe(map(() => undefined));
  }

  getSlideshowsViewsCount(slideshowIds: string[]): Observable<Record<string, number>> {
    return this.apollo
      .query({
        query: this.slideshowsViewsCount,
        variables: {
          slideshowIds,
        },
      })
      .pipe(
        filter(result => !!result.data),
        map(result => result.data?.slideshowsViewsCount)
      );
  }

  trackSlideshowView(slideshowId: string): Observable<void> {
    return this.apollo
      .mutate({
        mutation: this.trackSlideshowViewMutation,
        variables: {
          slideshowId,
        },
      })
      .pipe(map(() => undefined));
  }
}
