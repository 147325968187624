import {Injectable, InjectFlags, InjectionToken, Injector} from '@angular/core';
import {ExtractFunction, IPayload} from '../interfaces/request.interface';
import {IBackendContext} from '../interfaces/backend-context.interface';

@Injectable()
export class BackendContextService implements IBackendContext {
  constructor(private readonly injector: Injector) {}

  sendRequest<T, K extends keyof ExtractFunction<T>>(
    strategyToken: InjectionToken<ExtractFunction<T>>,
    payload: IPayload<T, K>
  ): ReturnType<ExtractFunction<T>[K]> {
    const requestStrategy = this.injector.get(strategyToken, null, InjectFlags.Optional);
    const method = requestStrategy?.[payload.method];

    if (!requestStrategy) {
      throw new Error(`${strategyToken.toString()} is not provided.`);
    }

    if (!method) {
      console.warn(`Called method of ${strategyToken.toString()} is not defined.`);
    }

    return method?.apply(requestStrategy, payload.params ?? []);
  }
}
