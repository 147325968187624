import {NgModule} from '@angular/core';
import {ErrorInterceptor} from './interceptors/error.interceptor';
import {HttpXSRFInterceptor} from './interceptors/http-xsrf.interceptor';
import {ScopeInterceptor} from './interceptors/scope.interceptor';
import {ByPassServiceWorkerInterceptor} from './interceptors/by-pass-service-worker-interceptor';
import {ByPassServiceWorkerClientInterceptor} from './interceptors/by-pass-service-worker-client-interceptor';

@NgModule({
  providers: [
    ErrorInterceptor,
    HttpXSRFInterceptor,
    ScopeInterceptor,
    ByPassServiceWorkerInterceptor,
    ByPassServiceWorkerClientInterceptor,
  ],
})
/**
 * @deprecated use domain
 */
export class ApiModule {}
